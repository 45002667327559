import React from 'react'

export const AboutMock = () => {
  return (
    <div className="about-container">
      <p>
        A família Boa chegou ao Brasil em 1898, vieram para trabalhar nas
        lavouras. Com muito esforço conseguiram comprar um sítio no Bairro do
        Engordadouro em Jundiaí. E foi neste sítio que construíram uma olaria.
      </p>
      <p>
        Um certo dia, os fundadores do conhecido Hospital Santa Elisa em Jundiaí
        fizeram um grande pedido de tijolos para a construção do mesmo e foi com
        este dinheiro que os filhos da família Boa tiveram a idéia de abrir um
        armazém.
      </p>
      <p>
        Em 1966 inauguraram o armazém Santa Rosa nome dado em homenagem a
        matriarca da família Dona Rosa.
        <br />
        Este armazém ficou localizado no Bairro da Vila Hortolândia e em 1973
        com a mudança de armazém para auto serviço passou a se chamar
        Supermercados Boa.
      </p>
    </div>
  )
}
